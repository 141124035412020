import {navMenu} from "@/constant/constanst";

const noticeRoutes = [
    {
        path: '/notice',
        meta: {
            title: navMenu[3].title,
            subTitle: navMenu[3].subTitle,
            classList: navMenu[3].classList,
            navRoutes: navMenu[3].children
        },
        component: () => import("@/views/MainLayout.vue"),
        children: [
            {
                path: 'association',
                component: () => import("@/views/kor/notice/Association.vue")
            },
            {
                path: 'association/:id',
                component: () => import("@/views/kor/notice/AssociationDetail.vue")
            },
            {
                path: 'members',
                component: () => import("@/views/kor/notice/Members.vue")
            },
            {
                path: 'members/:id',
                component: () => import("@/views/kor/notice/MembersDetail.vue")
            },
            {
                path: 'job',
                component: () => import("@/views/kor/notice/JobOpening.vue")
            },
            {
                path: 'job/:id',
                component: () => import("@/views/kor/notice/JobOpeningDetail.vue")
            },
            {
                path: 'government',
                component: () => import("@/views/kor/notice/GovernmentBusiness.vue")
            },
            {
                path: 'government/:id',
                component: () => import("@/views/kor/notice/GovernmentBusinessDetail.vue")
            },
            {
                path: 'schedule',
                meta: {
                  type: "ALL"
                },
                component: () => import("@/views/kor/notice/AssociationSchedule.vue")
            },
        ]
    }
]

export default noticeRoutes
