import {navMenu} from "@/constant/constanst";

const memberRoutes = [
    {
        path: '/member',
        meta: {
            title: navMenu[1].title,
            subTitle: navMenu[1].subTitle,
            classList: navMenu[1].classList,
            navRoutes: navMenu[1].children
        },
        component: () => import("@/views/MainLayout.vue"),
        children: [
            {
                path: 'list',
                component: () => import("@/views/kor/members/BrandAndMembers.vue")
            },
            {
                path: 'join-info',
                component: () => import("@/views/kor/members/JoinInfo.vue")
            },

        ]
    }
]

export default memberRoutes
