import axiosInstance from "@/api/axiosInstance";
import {fileCheck} from "@/utils/utils";

export const fileStore = {
    state: () => ({
        fileLoading: false
    }),
    getters: {
        getFileLoading(state) {
            return state.fileLoading
        }
    },
    actions: {
        uploadFile: async ({commit}, {url = '/files/upload', files}) => {
            try {
                if(!fileCheck(files)) return false;
                commit('setFileLoading', true)
                let form = new FormData();
                Object.keys(files).forEach(file => {
                    form.append("files", files[file])
                })
                return await axiosInstance.post(url, form, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
            } catch (e) {
                console.log(e)
                alert('파일업로드에 실패하였습니다.')
                throw e
            } finally {
                commit('setFileLoading', false)
            }
        },
    },
    mutations: {
        setFileLoading(state, value) {
            state.fileLoading = value
        }
    }
}
