import axios from "axios";
import store from "../store";
import router from "../router"
import {permitUrl} from "@/constant/constanst";
import {InvalidTokenException} from "@/exception";
// import {InvalidTokenException} from "@/exception";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    }
})

axiosInstance.defaults.onUploadProgress = progressEvent => {
    // 업로드된 퍼센트 계산
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    store.dispatch('setProgressBarValue', percentCompleted * 0.01).then(() => {
    })
};
axiosInstance.interceptors.request.use(
    function (config) {
        const authInfo = sessionStorage.getItem('quantum_auth');
        if (authInfo) {
            let auth = JSON.parse(authInfo)
            config.headers["Authorization"] = `Bearer ${auth?.accessToken}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const authInfo = sessionStorage.getItem('quantum_auth');
        const path = location.pathname
        if (!authInfo) {
            if (path.includes('user') && !permitUrl.includes(path)) {
                await router.replace("/user/login")
                return;
            }
        }
        const {
            // config,
            response: {status},
        } = error

        if ((status === 401 || status === '401' ) && path.includes('user') && !permitUrl.includes(path)) {
            alert('로그인이 필요합니다.')
            await router.replace("/user/login")
            return Promise.reject(new InvalidTokenException("로그인이 필요합니다."));
        }
        if (status === 403 || status === '403') {
            // if (path.includes('user') && !permitUrl.includes(path)) {
            //     alert('권한이 없습니다.')
            //     return router.back()
            // }
            if (!path.includes('user') && !permitUrl.includes(path)) {
                sessionStorage.removeItem('quantum_auth')
            }
            alert('권한이 없습니다.')
            return router.back()
        }
        return Promise.reject(error);
    }
)
export default axiosInstance
