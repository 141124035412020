<script setup>
import {useStore} from "vuex";

const store = useStore()
const authInfo = sessionStorage.getItem('quantum_auth')
if(authInfo) {
  store.commit('setAuthInfo', JSON.parse(authInfo))
}


</script>
<template>
  <router-view/>
</template>

<style>

::-webkit-scrollbar {
  display: none;
}
</style>
