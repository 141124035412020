import axiosInstance from "@/api/axiosInstance";
import {InvalidTokenException, NotAuthorizedException} from "@/exception";
import { useNProgress } from '@vueuse/integrations/useNProgress'
const { progress } = useNProgress()
const boardStore = {
    actions: {
        getBoardDetail: async ({commit}, {boardId = null, query ={}}) => {
            try {

                if (!boardId) return;
                commit('setIsPageLoading', true)
                progress.value = 0.5
                const {data} = await axiosInstance.get(`/boards/detail/${boardId}`, {
                    params: {
                        ...query
                    }
                })
                return data
            } catch (e) {
                if (e instanceof NotAuthorizedException || e instanceof InvalidTokenException)
                    alert(e.message);
                // else
                //     console.log(e)
                throw e
            } finally {
                progress.value = 1.0
                commit('setIsPageLoading', false)
            }
        },
    },
}

export default boardStore
