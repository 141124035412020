import axiosInstance from '@/api/axiosInstance'
import {useStorage} from "@vueuse/core";
import {errorAlert} from "@/utils/utils";

const authStore = {
    state: () => ({
        authInfo: {},
        userInfo: {}
    }),
    actions: {
        signIn: async ({commit, dispatch}, {model}) => {
            try {
                dispatch('setProgressBarValue', 0.1)
                const {data} = await axiosInstance.post('/auth/login', {
                    ...model
                })

                commit('setAuthInfo', data)

                const sessionState = useStorage(
                    'quantum_auth',
                    {...data},
                    sessionStorage
                )
                return sessionState.value
            } catch (e) {
                alert('아이디 혹은 비밀번호를 확인해주세요.')
                throw e
            } finally {
                dispatch('setProgressBarValue', 1.0)
            }
        },
        fetchUserInfo: async ({commit}) => {
            try {
                const {data} = await axiosInstance.get('/user-only/members/me')
                commit('setUserInfo', data)
                return data
            } catch (e) {
                errorAlert(e, '회원정보를 가져오지 못했습니다.')
                throw e
            }
        }
    },
    getters: {
        getSessionState: () => {
            return sessionStorage.getItem('quantum_auth');
        },
        getAuthInfo: (state) => state.authInfo,
        getUserInfo: (state) => state.userInfo
    },
    mutations: {
        setAuthInfo: (state, authInfo) => {
            state.authInfo = authInfo
        },
        setUserInfo: (state, userInfo) => {
            state.userInfo = userInfo
        }
    }
}

export default authStore
