<script setup>

import {useRoute} from "vue-router";
import FooterComponent from "@/components/FooterComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
const route = useRoute()

</script>

<template>
  <div id="wrap">
    <HeaderComponent />
    <router-view :key="route.query"/>
    <FooterComponent />
  </div>
</template>

<style scoped>
</style>
