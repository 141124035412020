import dayjs from "dayjs";
import 'dayjs/locale/ko';
import router from "@/router"
import store from '@/store'
dayjs.locale('ko')

//날짜 관련 format 함수
export const convertDateFormat = (date, format = 'YYYY-MM-DD') => {
    if (!date) return ''
    return dayjs(date).format(format)
}

export const makeQueryString = (params) => {
    let result = ''
    let param = Object.keys(params)
    param.forEach((key, index) => {
        result = result + key + '=' + params[key]
        if (param.length - 1 !== index) {
            result = result + '&'
        }
    })
    return result
}

/**
 * 이전 URL, 현재 URL 비교
 * @return {boolean}
 */
export const compareBackAndCurrnetUrl = () => {
    return JSON.stringify(window.history.state.back) === JSON.stringify(window.history.state.current)
}

/**
 * 총 페이지 갯수, 5개씩 끊어서 중첩 리스트
 * @param num
 * @return [ [], [], [], ]
 */
export const convertToArrayWithNumber = (num) => {
    const arr = [];
    let subArr = [];
    for (let i = 1; i <= num; i++) {
        subArr.push(i);
        if (i % 5 === 0 || i === num) {
            arr.push(subArr);
            subArr = [];
        }
    }
    return arr;
}

//첨부파일 다운로드
export const downloadFile = async (file) => {
    if (!file['fileId']) return;
    let url = document.createElement('a')
    url.setAttribute('href', `${process.env.VUE_APP_API_URL}/files/download/${file.fileId}`)
    url.setAttribute('download', '')
    url.style.display = 'none';
    url.click()
    url.remove()
}

//가입양식 다운로드
export const downloadApplicationFrom = async (category) => {
    let url = document.createElement('a')
    url.setAttribute('href', `${process.env.VUE_APP_API_URL}/files/download/application-form/${category}`)
    url.setAttribute('download', '')
    url.style.display = 'none';
    url.click()
    url.remove()
}

//유효성 검사 공통 로직
export const formCheck = (values, errorMessage) => {
    if (
        values === '' ||
        values === null ||
        typeof values === 'undefined' ||
        values === false
    ) {
        alert(errorMessage)
        return false
    } else if (Array.isArray(values)) {
        if (values.length <= 0) {
            alert(errorMessage)
            return false
        }
    }
    return true
}


//파일 관련 함수
const maxSize = 1024 * 1024 * 5 //10MB (뒤에 숫자에 따라 10이면 10MB)
const fileExtensions = ['jpeg', 'jpg', 'gif', 'png', 'hwp', 'hwpx', 'doc', 'docx', 'ppt', 'pptx', 'pdf', 'xlsx', 'xls']
export const fileCheck = (files) => {
    let flag = true
    // if(fileExtensions.includes(file))
    for (let i = 0; i < files.length; i++) {
        let index = files[`${i}`]['name'].lastIndexOf(".")
        let fileType = files[`${i}`]['name'].substring(index + 1, files[`${i}`]['name'].length).toLowerCase()
        if (!flag) {
            break
        }

        if (!fileExtensions.includes(fileType)) {
            alert(fileType + '의 확장자는 업로드 할 수 없습니다.')
            flag = false
        }
        if (files[`${i}`]['size'] > maxSize) {
            alert('5MB 이상 파일은 업로드 할 수 없습니다.')
            flag = false
        }

    }
    return flag
}

//에러 알럿 공통 함수
export const errorAlert = (error, message) => {
    alert(error?.response?.data?.message ? error?.response?.data?.message : message)
}

export const logout = () => {
    if(!confirm('로그아웃 하시겠습니까?')) return;
    sessionStorage.removeItem('quantum_auth')
    store.commit('setAuthInfo', {})
    router.replace('/main')
}
