<script setup>
import {useRoute} from "vue-router";
// import {useStore} from "vuex";
// import {computed} from "vue";
// import {logout} from "@/utils/utils";

const route = useRoute()
// const store = useStore()
// const authInfo = computed(() => store.getters.getAuthInfo)
</script>

<template>
  <footer v-if="!route.path.includes('eng')">
    <div class="ft_line" >
      <router-link to="/"><div class="logo"></div></router-link>
<!--      <ul>-->
<!--        <li><router-link to="/intro/map">오시는 길</router-link></li>-->
<!--      </ul>-->
    </div>
    <p><router-link to="/intro/map">한국양자산업협회 사무국</router-link></p>
    <p>© 2024. KQIA. All Rights Reserved.</p>
<!--    <p style="text-align: end; padding-bottom: 10px;"-->
<!--       v-if="Object.keys(authInfo).length > 0">-->
<!--      <a @click="logout">로그아웃</a>-->
<!--    </p>-->
  </footer>
  <footer class="ft_eng" v-else>
    <div class="ft_line">
      <router-link to="/eng/main"><div class="logo"></div></router-link>
<!--      <ul>-->
<!--        <li><router-link to="/eng/about-us/map">Map</router-link></li>-->
<!--      </ul>-->
    </div>
    <p><router-link to="/eng/about-us/map">Contact Us</router-link></p>
    <p>© 2024. KQIA. All Rights Reserved.</p>
<!--    <p style="text-align: end; padding-bottom: 10px;"-->
<!--       v-if="Object.keys(authInfo).length > 0">-->
<!--      <a @click="logout">logout</a>-->
<!--    </p>-->
  </footer>
</template>

<style scoped>

</style>
