<script setup>

import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import {useRoute} from "vue-router";
const route = useRoute()

</script>

<template>
  <div id="wrap">
<!--    <HeaderComponent :scroll-position="scrollPosition"/>-->
<!--    <router-view @scrollEvents="scrollEvents"/>-->
    <HeaderComponent />
    <router-view :key="route.query"/>
    <FooterComponent />
  </div>
</template>

<style scoped>
</style>
