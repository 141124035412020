import {createRouter, createWebHistory} from 'vue-router'
import BasicLayout from "@/views/BasicLayout.vue";
import introRoutes from "@/router/modules/introRoutes"
import memberRoutes from "@/router/modules/memberRoutes";
import archiveRoutes from "@/router/modules/archiveRoutes";
import mapRoutes from "@/router/modules/mapRoutes";
import promotionRoutes from "@/router/modules/promotionRoutes";
import noticeRoutes from "@/router/modules/noticeRoutes";
import {useNProgress} from '@vueuse/integrations/useNProgress'
import store from "@/store";
import {permitUrl} from "@/constant/constanst";
// import onlyUserRoutes from "@/router/modules/OnlyUserRoutes";
import {engRoutes} from "@/router/modules/engRoutes";
import NotFoundView from "@/views/NotFoundView.vue";

const {done, isLoading} = useNProgress()
// eslint-disable-next-line no-unused-vars
const routes = [
    {
        path: '/',
        component: BasicLayout,
        redirect: '/main',
        children: [
            {
                path: '/main',
                component: () => import("@/views/MainPage.vue")
            },
            ...introRoutes,
            ...memberRoutes,
            ...archiveRoutes,
            ...promotionRoutes,
            ...noticeRoutes,
            ...mapRoutes
        ]
    },
    ...engRoutes, //영문페이지
    // ...onlyUserRoutes, //회원사 전용 페이지
    {
        path: `/:pathMatch(.*)`,
        component: NotFoundView
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})


router.beforeEach((to, from, next) => {
    if (isLoading) done()
    store.commit("resetPageable")
    if (to.path.includes('user')) {
        //유저단 접근할 때,
        if (!permitUrl.includes(to.path)) {
            const auth = sessionStorage.getItem('quantum_auth')
            // 로그인 없이 가능한 페이지가 아닐 경우
            if (typeof auth === 'undefined' || auth === null || !auth) {
                alert('로그인이 필요합니다.')
                next('/user/login')
            } else {
                const isTempPw = JSON.parse(auth)
                if (isTempPw['isTempPassword'] && to.path !== '/user/mypage/edit-password') {
                    alert('임시비밀번호는 이용에 제한됩니다. 비밀번호를 변경해주세요.')
                    next('/user/mypage/edit-password')
                }
            }

        }
        next()
    }
    // 처리 완료 후, 다음 단계 진행
    next();
})
router.afterEach(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    const wholeHtml = document.querySelector("html")
    wholeHtml.classList.remove('open')
})

export default router
