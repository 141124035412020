import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


//국문
import "@/assets/css/main.css"
import "@/assets/css/modal.css"
import "@/assets/css/style.css"
import "@/assets/css/sub.css"

//회원사
import "@/assets/css/main_b.css"
import "@/assets/css/modal_b.css"
import "@/assets/css/style_b.css"
import "@/assets/css/sub_b.css"

//영문
import "@/assets/css/main_eng.css"
import "@/assets/css/modal_eng.css"
import "@/assets/css/style_eng.css"
import "@/assets/css/sub_eng.css"

//개발시 수정 사항
import "@/assets/css/modify.css"

createApp(App)
    .use(store)
    .use(router)
    .mount('#app')
