<script setup>
import {onBeforeUnmount, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";

const router = useRouter(), route = useRoute()

const scrollPosition = ref(0)
const scrollEvents = () => {
  scrollPosition.value = window.scrollY
}

const goToTop = () => {
  window.scrollTo({top: 0, behavior: 'smooth'});
}

const mobileMenu = ref(false)
const clickMobileMenuIcon = (isOpen) => {
  const wholeHtml = document.querySelector("html")
  mobileMenu.value = isOpen
  if (isOpen) {
    wholeHtml.className += 'open'
  } else {
    wholeHtml.classList.remove('open')
  }
}
const mobileMenus = ref([
  {
    name: '협회소개',
    show: false,
    child: [
      {name: '소개', path: '/intro/about-us'},
      {name: '인삿말', path: '/intro/greet'},
      {name: '조직안내', path: '/intro/structure'},
      {name: '사무국 문의', path: '/intro/map'},
    ]
  },
  {
    name: '회원사',
    show: false,
    child: [
      {name: '회원사', path: '/member/list'},
      {name: '가입안내', path: '/member/join-info'},
    ]
  },
  {
    name: '홍보자료',
    show: false,
    child: [
      {name: '언론보도', path: '/promotion/report'},
      {name: '주요활동', path: '/promotion/activity'},
    ]
  },
  {
    name: '알림마당',
    show: false,
    child: [
      {name: '협회소식', path: '/notice/association'},
      {name: '회원사소식', path: '/notice/members'},
      {name: '회원사채용공고', path: '/notice/job'},
      {name: '정부사업공고', path: '/notice/government'},
      {name: '협회일정', path: '/notice/schedule'},
    ]
  },
  {
    name: '자료실',
    show: false,
    child: [
      {name: '자료실', path: '/archive'},
    ]
  },
])

const mobileEngMenus = ref([
  {
    name: 'About us',
    show: false,
    child: [
      {name: 'About KQIA', path: '/eng/about-us/introduce'},
      // {name: 'Greeting', path: '/eng/about-us/greeting'},
      {name: 'Organization', path: '/eng/about-us/organization'},
      {name: 'Contact Us', path: '/eng/about-us/map'},
    ]
  },
  {
    name: 'Members',
    show: false,
    child: [
      {name: 'KQIA Member List', path: '/eng/members/list'},
      {name: 'Membership Application', path: '/eng/members/reg-info'},
    ]
  },
  {
    name: 'PR',
    show: false,
    child: [
      {name: 'Media', path: '/eng/pr/media'},
      {name: 'Activities', path: '/eng/pr/activities'},
    ]
  },
  {
    name: 'News',
    show: false,
    child: [
      {name: 'KQIA News', path: '/eng/news/association'},
      {name: 'KQIA Member News', path: '/eng/news/members'},
    ]
  },
  {
    name: 'Resource Library',
    show: false,
    child: [
      {name: 'Resource Library', path: '/eng/reference'},
    ]
  },
])
const clickMobileMenu = ({clickedIndex, type}) => {
  if(type ==='kor') {
    const openIndex = mobileMenus.value.findIndex(item => item.show)
    if (openIndex !== -1) {
      mobileMenus.value[openIndex].show = !mobileMenus.value[openIndex].show
    }
    mobileMenus.value.forEach((item, itemIndex) => {
      item['show'] = clickedIndex === itemIndex
    })
  }
  if(type === 'eng') {
    const openIndex = mobileEngMenus.value.findIndex(item => item.show)
    if (openIndex !== -1) {
      mobileEngMenus.value[openIndex].show = !mobileEngMenus.value[openIndex].show
    }
    mobileEngMenus.value.forEach((item, itemIndex) => {
      item['show'] = clickedIndex === itemIndex
    })
  }
}

onMounted(() => {
  document.addEventListener('scroll', scrollEvents)
})

onBeforeUnmount(() => {
  document.removeEventListener('scroll', scrollEvents)
})
</script>

<template>
  <a @click="goToTop" class="gotop" v-if="scrollPosition !== 0" style="display: block">
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <path d="M1 15L8.5 7.5L16 15" stroke="#CCCCCC" stroke-width="2"/>
      <path d="M1 9.5L8.5 2L16 9.5" stroke="#CCCCCC" stroke-width="2"/>
    </svg>
    <p>TOP</p>
  </a>
  <div id="header" v-if="!route.path.includes('eng')" :class="{'active': scrollPosition !== 0}">
    <nav>
      <router-link to="/main">
        <div class="logo"></div>
      </router-link>
      <ul>
        <li :class="{'on': route.path.includes('intro')}">
          <router-link to="/intro/about-us">협회소개</router-link>
        </li>
        <li :class="{'on': route.path.includes('member')}">
          <router-link to="/member/list">회원사</router-link>
        </li>
        <li :class="{'on': route.path.includes('promotion')}">
          <router-link to="/promotion/report">홍보자료</router-link>
        </li>
        <li :class="{'on': route.path.includes('notice')}">
          <router-link to="/notice/association">알림마당</router-link>
        </li>
        <li :class="{'on': route.path.includes('archive')}">
          <router-link to="/archive">자료실</router-link>
        </li>
      </ul>
      <ul>
<!--        <li style=" display: none;" @click="router.push('/user/main')"><a>회원사 전용</a></li>-->
        <li style=" display: none;"><a></a></li>
        <li @click="router.push('/eng/main')"><a>ENGLISH</a></li>
      </ul>
      <!--1450px이하-->
      <div class="btn_topmenu" @click="clickMobileMenuIcon"></div>
      <div @click="router.back();" class="page_cover" :class="{'open' : mobileMenu}"></div>
      <div id="topmenu_body" :class="{'open' : mobileMenu}">
        <div class="top">
          <img src="@/assets/images/logo.png" alt="">
          <div class="close" @click="clickMobileMenuIcon(false)"></div>
        </div>
        <ul class="AccordionMenu">
          <li v-for="(menu, menuIndex) in mobileMenus" :key="menu['name']" :class="{'show': menu['show']}">
            <a class="Question" @click="clickMobileMenu({ clickedIndex: menuIndex, type: 'kor'})">{{ menu['name'] }}</a>
            <ul class="Answer">
              <li v-for="subMenu in menu['child']" :key="subMenu['name']">
                <router-link @click="mobileMenu = false" :to="subMenu['path']">{{ subMenu['name'] }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
        <ul>
<!--          <li>-->
<!--            <router-link to="/user/main">회원사 전용</router-link>-->
<!--          </li>-->
          <li>
            <router-link to="/eng/main">ENGLISH</router-link>
          </li>
        </ul>
      </div>
    </nav>
    <div class="menubars">
      <ul>
        <li class="ci">
          <ul>
            <li>
              <router-link to="/intro/about-us">소개</router-link>
            </li>
            <li>
              <router-link to="/intro/greet">인사말</router-link>
            </li>
            <li>
              <router-link to="/intro/structure">조직안내</router-link>
            </li>
            <li>
              <router-link to="/intro/map">사무국 문의</router-link>
            </li>
          </ul>
        </li>
        <li class="ci">
          <ul>
            <li>
              <router-link to="/member/list">회원사</router-link>
            </li>
            <li>
              <router-link to="/member/join-info">가입안내</router-link>
            </li>
          </ul>
        </li>
        <li class="ci">
          <ul>
            <li>
              <router-link to="/promotion/report">언론보도</router-link>
            </li>
            <li>
              <router-link to="/promotion/activity">주요활동</router-link>
            </li>
          </ul>
        </li>
        <li class="ci">
          <ul>
            <li>
              <router-link to="/notice/association">협회소식</router-link>
            </li>
            <li>
              <router-link to="/notice/members">회원사소식</router-link>
            </li>
            <li>
              <router-link to="/notice/job">회원사 채용정보</router-link>
            </li>
            <li>
              <router-link to="/notice/government">정부사업공고</router-link>
            </li>
            <li>
              <router-link to="/notice/schedule">협회일정</router-link>
            </li>
          </ul>
        </li>
        <li class="ci">
          <ul>
            <li>
              <router-link to="/archive">자료실</router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div id="header_eng" v-else>
    <nav>
      <router-link to="/eng/main">
        <div class="logo"></div>
      </router-link>
      <ul>
        <li :class="{'on': route.path.includes('/eng/about-us')}">
          <router-link to="/eng/about-us/introduce">About Us</router-link>
        </li>
        <li :class="{'on': route.path.includes('/eng/members')}">
          <router-link to="/eng/members/list">Members</router-link>
        </li>
        <li :class="{'on': route.path.includes('/eng/pr')}">
          <router-link to="/eng/pr/media">PR</router-link>
        </li>
        <li :class="{'on': route.path.includes('/eng/news')}">
          <router-link to="/eng/news/association">News</router-link>
        </li>
        <li :class="{'on': route.path.includes('/eng/reference')}">
          <router-link to="/eng/reference">Resource Library</router-link>
        </li>
      </ul>
      <ul>
        <li @click="router.push('/main')">KOREAN</li>
      </ul>
      <!--1450px이하-->
      <div class="btn_topmenu" @click="clickMobileMenuIcon"></div>
      <div @click="router.back()" class="page_cover" :class="{'open' : mobileMenu}"></div>
      <div id="topmenu_body" :class="{'open' : mobileMenu}">
        <div class="top">
          <img src="@/assets/images/logo_eng.png" alt="">
          <div @click="clickMobileMenuIcon(false)" class="close"></div>
        </div>
        <ul class="AccordionMenu">
          <li v-for="(menu, menuIndex) in mobileEngMenus" :key="menu['name']" :class="{'show': menu['show']}">
            <a class="Question" @click="clickMobileMenu({ clickedIndex: menuIndex, type: 'eng'})">{{ menu['name'] }}</a>
            <ul class="Answer">
              <li v-for="subMenu in menu['child']" :key="subMenu['name']">
                <router-link @click="mobileMenu = false" :to="subMenu['path']">{{ subMenu['name'] }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li @click="router.push('/main')">KOREAN</li>
        </ul>
      </div>
    </nav>
    <div class="menubars">
      <ul>
        <li class="ci">
          <ul>
            <li>
              <router-link to="/eng/about-us/introduce">About KQIA</router-link>
            </li>
<!--            <li>-->
<!--              <router-link to="/eng/about-us/greeting">Greeting</router-link>-->
<!--            </li>-->
            <li>
              <router-link to="/eng/about-us/organization">Organization</router-link>
            </li>
            <li>
              <router-link to="/eng/about-us/map">Contact Us</router-link>
            </li>
          </ul>
        </li>
        <li class="ci">
          <ul>
            <li>
              <router-link to="/eng/members/list">KQIA Member List</router-link>
            </li>
            <li>
              <router-link to="/eng/members/reg-info">Membership Application</router-link>
            </li>
          </ul>
        </li>
        <li class="ci">
          <ul>
            <li>
              <router-link to="/eng/pr/media">Media</router-link>
            </li>
            <li>
              <router-link to="/eng/pr/activities">Activities</router-link>
            </li>
          </ul>
        </li>
        <li class="ci">
          <ul>
            <li>
              <router-link to="/eng/news/association">KQIA News</router-link>
            </li>
            <li>
              <router-link to="/eng/news/members">KQIA Member News</router-link>
            </li>
          </ul>
        </li>
        <li class="ci">
          <ul>
            <li>
              <router-link to="/eng/reference">Resource Library</router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>

</style>
