<template>
    <div id="notfound">
        <div class="notfound">
            <div class="notfound-404">
                <h1>..</h1>
            </div>
            <h2>404 - Page not found</h2>
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <router-link to="./">이전 페이지로 가기</router-link>
        </div>
    </div>
</template>

<style scoped>
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
}

#notfound {
    position: relative;
    height: 100vh;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 710px;
    width: 100%;
    padding-left: 190px;
    line-height: 1.4;
}

.notfound .notfound-404 {
    position: absolute;
    left: 0;
    top: 0;
    width: 150px;
    height: 150px;
    text-align: center;
    vertical-align: center;
}

.notfound .notfound-404 h1 {
    display: inline;
    color: #000040;
    font-weight: 900;
    font-size: 40px;
    background: linear-gradient(180deg, #4D20A8 0%, #ffffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.notfound h2 {
    font-family: 'Raleway', sans-serif;
    color: #292929;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin-top: 0;
}

.notfound p {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 15px;
    color: #333;
}

.notfound a {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    background: #fff;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 40px;
    color: #292929;
    font-weight: 700;
    -webkit-box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.3);
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}

.notfound a:hover {
    color: #fff;
    background-color: #4D20A8;
}

@media only screen and (max-width: 480px) {
    .notfound {
        text-align: center;
    }
    .notfound .notfound-404 {
        position: relative;
        width: 100%;
        margin-bottom: 15px;
    }
    .notfound {
        padding-left: 15px;
        padding-right: 15px;
    }
}
</style>
