import {navMenu} from "@/constant/constanst";
const mapRoutes = [
    {
        path: '/map',
        meta: {
            pageActive: ['map'],
            title: navMenu[5].title,
            subTitle: navMenu[5].subTitle,
            classList: navMenu[5].classList,
            navRoutes: navMenu[5].children
        },
        component: () => import("@/views/MainLayout.vue"),
        children: [
            {
                path: '',
                component: () => import("@/views/kor/map")
            },
        ]
    }
]

export default mapRoutes
