import {navMenu} from "@/constant/constanst";
// const navRoutes = [
//     {
//         to: '/intro/about-us',
//         title: '소개',
//     },
//     {
//         to: '/intro/greet',
//         title: '인사말',
//     },
//     {
//         to: '/intro/structure',
//         title: '조직안내',
//     },
//     {
//         to: '/intro/history',
//         title: '연혁',
//     },
//     {
//         to: '/intro/location',
//         title: '오시는 길',
//     },
// ]

const introRoutes = [
    {
        path: '/intro',
        meta: {
            pageActive: ['intro'],
            title: navMenu[0].title,
            subTitle: navMenu[0].subTitle,
            classList: navMenu[0].classList,
            navRoutes: navMenu[0].children
        },
        component: () => import("@/views/MainLayout.vue"),
        children: [
            {
                path: 'about-us',
                component: () => import("@/views/kor/introduce/AboutUs.vue")
            },
            {
                path: 'greet',
                component: () => import("@/views/kor/introduce/Greeting.vue")
            },
            {
                path: 'structure',
                component: () => import("@/views/kor/introduce/Structure.vue")
            },
            {
                path: 'history',
                component: () => import("@/views/kor/introduce/History.vue")
            },
            {
                path: 'location',
                component: () => import("@/views/kor/introduce/Location.vue")
            },
            {
                path: 'map',
                component: () => import("@/views/kor/introduce/Map.vue")
            },
        ]
    }
]

export default introRoutes
