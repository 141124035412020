import router from '@/router/index'
import {useNProgress} from "@vueuse/integrations/useNProgress";
// import {useSessionStorage} from "@vueuse/core";
const {progress} = useNProgress()

const commonStore = {
    state: () => ({
        loginModalOpen: false,
        redirectUrl: {}
    }),
    actions: {
        checkBoardAuth: async ({commit}, {board = null, url = ''}) => {
            if (!board) return;
            if (board.permission === 'ASSOCIATE') {
                // const loginInfo = useSessionStorage()
                commit('setIsPageLoading', true)
            } else {
                await router.push(url)
            }
        },
        setProgressBarValue: (_, progressBarValue) => {
            progress.value = progressBarValue
        },

        //Promise.all , Promise.allSettled 함수를 위한 로직
        fetchHttpApis: async ({dispatch, commit}, {callback, params}) => {
            try {
                progress.value = 0.1
                dispatch('setProgressBarValue', 0.5)
                commit('setIsPageLoading', true)
                return await callback(params)
            } catch (e) {
                console.log(e)
                throw e
            } finally {
                dispatch('setProgressBarValue', 1.0)
                commit('setIsPageLoading', false)
            }
        }
    },
    getters: {
        getLoginModalOpen: state => state.loginModalOpen,
        getRedirectUrl: state => state.redirectUrl,
    },
    mutations: {
        setRedirectUrl: (state, url) => {
            state.redirectUrl = url
        },
        setLoginModalOpen: (state, loginModalOpen) => {
            state.loginModalOpen = loginModalOpen
        }
    }
}

export default commonStore
