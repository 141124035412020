export const permitUrl = ['/user/login', '/user/find-id', '/user/find-pw', '/user/sign-up']
// 비밀번호 정규식 메소드 8자리이상, 대문자, 소문자, 숫자, 특수문자
export const passwordRegex = /^.*(?=^.{8,}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*-+?]).*$/;
export const emailRegex = /^[\da-zA-Z]([-_.]?[\da-zA-Z])*@[\da-zA-Z]([-_.]?[\da-zA-Z])*.[a-zA-Z]{2,3}$/i;
export const navMenu = [
    {
        title: '협회소개',
        subTitle: 'About Us',
        classList: ['sub_vs', 'about'],
        to: '/intro/about-us',
        children: [
            {
                to: '/intro/about-us',
                title: '소개',
            },
            {
                to: '/intro/greet',
                title: '인사말',
            },
            {
                to: '/intro/structure',
                title: '조직안내',
            },
            {
                to: '/intro/map',
                title: '사무국 문의',
            },
        ]
    },
    {
        title: '회원사',
        subTitle: 'Members',
        classList: ['sub_vs', 'members'],
        to: '/member/list',
        children: [
            {
                to: '/member/list',
                title: '회원사',
            },
            {
                to: '/member/join-info',
                title: '가입안내',
            },
        ]
    },

    {
        title: '홍보자료',
        subTitle: 'News',
        classList: ['sub_vs', 'news'],
        to: '/promotion/report',
        children: [
            {
                to: '/promotion/report',
                title: '언론보도',
            },
            {
                to: '/promotion/activity',
                title: '주요활동',
            },
        ]
    },
    {
        title: '알림마당',
        subTitle: 'Notice',
        classList: ['sub_vs', 'news'],
        to: '/notice',
        children: [
            {
                to: '/notice/association',
                title: '협회소식',
            },
            {
                to: '/notice/members',
                title: '회원사소식',
            },
            {
                to: '/notice/job',
                title: '회원사채용공고',
            },
            {
                to: '/notice/government',
                title: '정부사업공고',
            },
            {
                to: '/notice/schedule',
                title: '협회일정',
            },
        ]
    },
    {
        title: '자료실',
        subTitle: 'Resource Library',
        classList: ['sub_vs', 'reference'],
        to: '/archive',
        children: [
            {
                to: '/',
                title: '자료실',
            }
        ]
    },
    {
        title: '사무국 문의',
        subTitle: 'Map',
        classList: ['sub_vs', 'map'],
        to: 'intro/map',
        children: [
            {
                to: 'intro/map',
                title: '',
            }
        ]
    },
]

export const engNavMenu = [
    {
        title: 'About Us',
        classList: ['sub_vs_eng', 'about'],
        to: '/eng/about-us/introduce',
        children: [
            {
                to: '/eng/about-us/introduce',
                title: 'About KQIA',
            },
            // {
            //     to: '/eng/about-us/greeting',
            //     title: 'Greeting',
            // },
            {
                to: '/eng/about-us/organization',
                title: 'Organization',
            },
            {
                to: '/eng/about-us/map',
                title: 'Contact Us',
            },
        ]
    },
    {
        title: 'Members',
        classList: ['sub_vs_eng', 'about'],
        to: '/eng/members/list',
        children: [
            {
                to: '/eng/members/list',
                title: 'KQIA Member List',
            },
            {
                to: '/eng/members/reg-info',
                title: 'Membership Application',
            },
        ]
    },
    {
        title: 'PR',
        classList: ['sub_vs_eng', 'news'],
        to: '/eng/pr/media',
        children: [
            {
                to: '/eng/pr/media',
                title: 'Media',
            },
            {
                to: '/eng/pr/activities',
                title: 'Activities',
            }
        ]
    },
    {
        title: 'News',
        classList: ['sub_vs_eng', 'news'],
        to: '/eng/news/association',
        children: [
            {
                to: '/eng/news/association',
                title: 'KQIA News',
            },
            {
                to: '/eng/news/members',
                title: 'KQIA Member News',
            }
        ]
    },
    {
        title: 'Resource Library',
        classList: ['sub_vs_eng', 'reference'],
        to: '/reference',
        children: [
            {
                to: '/eng/reference',
                title: 'Resource Library',
            }
        ]
    },
]
