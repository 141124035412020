//로그인 권한 에러
export class NotAuthorizedException extends Error {
    constructor(message) {
        super(message);
        this.name = "NotAuthorizedException";
    }
}

//만료된 토큰
export class InvalidTokenException extends Error {
    constructor(message) {
        super(message);
        this.name = "InvalidTokenException";
    }
}

//404
export class NotFoundException extends Error {
    constructor(message) {
        super(message);
        this.name = "NotFoundException";
    }
}

//서버에러
export class ServerErrorException extends Error {
    constructor(message) {
        super(message);
        this.name = "ServerErrorException";
    }
}
