import axiosInstance from "@/api/axiosInstance";
import {InvalidTokenException, NotAuthorizedException} from "@/exception";

// import {useUrlSearchParams} from '@vueuse/core'
// const params = useUrlSearchParams('history')

const pageStore = {
    state: () => ({
        content: [],
        totalPages: 0,
        totalElements: 0,
        last: true,
        first: true,
        number: 1,
        size: 20,
        queryParams: {},
        pageableLoading: false,
    }),
    mutations: {
        resetPageable: (state) => {
            state.content = []
            state.totalPages = 0
            state.totalElements = 0
            state.last = true
            state.first = true
            state.number = 1
            state.page = 1
            state.size = 10
        },
        setContent: (state, content) => {
            state.content = content
        },
        setIsPageLoading: (state, value) => {
            state.pageableLoading = value
        },
        setPageableData: (state, {data: {content, totalPages, totalElements, last, number, first}}) => {
            state.content = content
            state.totalPages = totalPages || 0
            state.totalElements = totalElements || 0
            state.number = number + 1
            state.last = last
            state.first = first
        },
        setPageNumber(state, number) {
            state.number = number
            return number
        },
        setTotalPages(state, totalPages) {
            state.totalPages = totalPages
            return totalPages
        },
        setQueryParams(state, params) {
            state.queryParams = params
            return state.queryParams
        },
    },
    actions: {
        fetchPageList: async ({state, dispatch, commit}, {searchParams = {}, url = ''}) => {
            try {

                if (!url) return;
                commit('setIsPageLoading', true)
                dispatch('setProgressBarValue', 0.5)
                let params = {
                    sort: 'id,desc',
                    size: 10,
                    page: state.number - 1,
                    ...searchParams
                }
                const result = await axiosInstance.get(url, {
                    params: {
                        ...params
                    },
                })
                commit('setPageableData', result)
                return result
            } catch (e) {
                if (e instanceof NotAuthorizedException || e instanceof InvalidTokenException)
                    alert(e.message);
                else
                    alert('목록을 가져오지 못했습니다.');
                throw e
            } finally {
                dispatch('setProgressBarValue', 1.0)
                commit('setIsPageLoading', false)
            }
        }
    },
    getters: {
        getContent: (state) => state.content,
        getTotalPages: (state) => state.totalPages,
        getTotalElements: (state) => state.totalElements,
        getLast: (state) => state.last,
        getFirst: (state) => state.first,
        getNumber: (state) => state.number,
        getIsPageLoading: (state) => state.pageableLoading,
        getQueryParams: (state) => state.queryParams,
    }
}

export default pageStore
