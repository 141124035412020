import {createStore} from 'vuex'
import pageableStore from "@/store/pageable";
import boardStore from "@/store/board";
import commonStore from "@/store/common";
import authStore from '@/store/auth'
import {fileStore} from "@/store/file";

export default createStore({
    modules: {
        pageableStore,
        boardStore,
        commonStore,
        authStore,
        fileStore,
    }
})
