import {navMenu} from "@/constant/constanst";

const promotionRoutes = [
    {
        path: '/promotion',
        meta: {
            title: navMenu[2].title,
            subTitle: navMenu[2].subTitle,
            classList: navMenu[2].classList,
            navRoutes: navMenu[2].children
        },
        component: () => import("@/views/MainLayout.vue"),
        children: [
            {
                path: 'report',
                component: () => import("@/views/kor/promotion/MediaReport.vue")
            },
            {
                path: 'report/:id',
                component: () => import("@/views/kor/promotion/MediaRepostDetail.vue")
            },
            {
                path: 'activity',
                component: () => import("@/views/kor/promotion/MajorActivity.vue")
            },
            {
                path: 'activity/:id',
                component: () => import("@/views/kor/promotion/MajorActivityDetail.vue")
            },

        ]
    }
]

export default promotionRoutes
