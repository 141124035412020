import BasicLayout from "@/views/eng/BasicLayout.vue";
import {engNavMenu} from "@/constant/constanst";

const prefix = '/eng'
const makeEngUrl = (url) => {
    if (!url) return prefix
    return `${prefix}${url}`
}
export const engRoutes = [
    {
        path: '/eng',
        component: BasicLayout,
        redirect: '/eng/main',
        children: [
            {
                name: 'EngMain',
                path: makeEngUrl('/main'),
                component: () => import('@/views/eng/MainPage.vue')
            },
            {
                path: 'about-us',
                meta: {
                    pageActive: ['about-us'],
                    title: engNavMenu[0].title,
                    classList: engNavMenu[0].classList,
                    navRoutes: engNavMenu[0].children
                },
                component: () => import("@/views/eng/MainLayout.vue"),
                children: [
                    {
                        path: 'introduce',
                        component: () => import("@/views/eng/aboutUs/IntroduceEng.vue")
                    },
                    {
                        path: 'greeting',
                        component: () => import("@/views/eng/aboutUs/GreetingEng.vue")
                    },
                    {
                        path: 'organization',
                        component: () => import("@/views/eng/aboutUs/OrganizationEng.vue")
                    },
                    {
                        path: 'map',
                        component: () => import("@/views/eng/aboutUs/MapEng.vue")
                    },
                ]
            },
            {
                path: 'members',
                meta: {
                    pageActive: ['members'],
                    title: engNavMenu[1].title,
                    classList: engNavMenu[1].classList,
                    navRoutes: engNavMenu[1].children
                },
                component: () => import("@/views/eng/MainLayout.vue"),
                children: [
                    {
                        path: 'list',
                        component: () => import("@/views/eng/members/BrandList.vue")
                    },
                    {
                        path: 'reg-info',
                        component: () => import("@/views/eng/members/RegInfo.vue")
                    },
                ]
            },
            {
                path: 'pr',
                meta: {
                    pageActive: ['pr'],
                    title: engNavMenu[2].title,
                    classList: engNavMenu[2].classList,
                    navRoutes: engNavMenu[2].children
                },
                component: () => import("@/views/eng/MainLayout.vue"),
                children: [
                    {
                        path: 'media',
                        component: () => import("@/views/eng/pr/MediaEng.vue")
                    },
                    {
                        path: 'media/:id(\\d+)',
                        component: () => import("@/views/eng/pr/MediaDetailEng.vue")
                    },
                    {
                        path: 'activities',
                        component: () => import("@/views/eng/pr/ActivitiesEng.vue")
                    },
                    {
                        path: 'activities/:id(\\d+)',
                        component: () => import("@/views/eng/pr/ActivitiesDetailEng.vue")
                    },
                ]
            },
            {
                path: 'news',
                meta: {
                    pageActive: ['news'],
                    title: engNavMenu[3].title,
                    classList: engNavMenu[3].classList,
                    navRoutes: engNavMenu[3].children
                },
                component: () => import("@/views/eng/MainLayout.vue"),
                children: [
                    {
                        path: 'association',
                        component: () => import("@/views/eng/news/AssociationEng.vue")
                    },
                    {
                        path: 'association/:id(\\d+)',
                        component: () => import("@/views/eng/news/AssociationDetailEng.vue")
                    },
                    {
                        path: 'members',
                        component: () => import("@/views/eng/news/MembersEng.vue")
                    },
                    {
                        path: 'members/:id(\\d+)',
                        component: () => import("@/views/eng/news/MembersDetailEng.vue")
                    },
                ]
            },
            {
                path: 'reference',
                meta: {
                    pageActive: ['reference'],
                    title: engNavMenu[4].title,
                    classList: engNavMenu[4].classList,
                    navRoutes: engNavMenu[4].children
                },
                component: () => import("@/views/eng/MainLayout.vue"),
                children: [
                    {
                        path: '',
                        component: () => import("@/views/eng/reference/index.vue")
                    },
                    {
                        path: ':id(\\d+)',
                        component: () => import("@/views/eng/reference/DetailView.vue")
                    }
                ]
            }
        ]
    }
]

