import {navMenu} from "@/constant/constanst";

const archiveRoutes = [
    {
        path: '/archive',
        meta: {
            pageActive: ['intro'],
            title: navMenu[4].title,
            subTitle: navMenu[4].subTitle,
            classList: navMenu[4].classList,
            navRoutes: navMenu[4].children
        },
        component: () => import("@/views/MainLayout.vue"),
        children: [
            {
                path: '',
                component: () => import("@/views/kor/archive")
            },
            {
                path: ':id',
                component: () => import("@/views/kor/archive/DetailView.vue")
            },
        ]
    }
]

export default archiveRoutes
